<template>
  <div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click="openModal">Добавить настройку</button>
    </div>

    <!-- Таблица -->
    <table class="table">
      <thead>
        <tr>
          <th>Ключ</th>
          <th>Значение</th>
          <th>Описание</th>
          <th class="text-end" width="100px">Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="setting in settings" :key="setting.key">
          <td>{{ setting.key }}</td>
          <td>{{ setting.value }}</td>
          <td>{{ setting.description }}</td>
          <td>
            <button class="btn btn-warning btn-sm me-2" @click="editSetting(setting)">Редактировать</button>
            <button class="btn btn-danger btn-sm" @click="confirmDelete(setting.key)">Удалить</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="modal fade" id="settingModal" tabindex="-1" aria-labelledby="settingModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditing ? 'Редактировать настройку' : 'Добавить настройку' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3">
                <label for="key" class="form-label">Ключ</label>
                <input type="text" class="form-control" id="key" v-model="form.key" required />
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Описание</label>
                <input type="text" class="form-control" id="description" v-model="form.description" />
              </div>
              <div class="mb-3">
                <label for="value" class="form-label">Значение</label>
                <input type="text" class="form-control" id="value" v-model="form.value" required />
              </div>

              <div class="d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary me-2">{{ isEditing ? 'Обновить' : 'Добавить' }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Подтверждение удаления</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Вы уверены, что хотите удалить эту настройку?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deleteSetting">Удалить</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Тост сообщения -->
    <div v-if="toastMessage" class="toast show position-fixed bottom-0 end-0 m-3" role="alert">
      <div class="toast-body">
        {{ toastMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import axiosInstance from '../services/axiosInstance';
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const settings = ref([]);
    const isEditing = ref(false);
    const form = ref({ key: '', description: '', value: '' });
    const deletingKey = ref(null);
    const toastMessage = ref('');

    const fetchSettings = async () => {
      try {
        const response = await axiosInstance.get('/settings');
        settings.value = response.data;
      } catch (error) {
        showToast('Ошибка при загрузке настроек');
      }
    };

    const openModal = () => {
      form.value = { key: '', description: '', value: '' };
      isEditing.value = false;
      const modal = new Modal(document.getElementById('settingModal'));
      modal.show();
    };

    const closeModal = () => {
      const modal = Modal.getInstance(document.getElementById('settingModal'));
      modal.hide();
    };

    const editSetting = (setting) => {
      form.value = { ...setting };
      isEditing.value = true;
      const modal = new Modal(document.getElementById('settingModal'));
      modal.show();
    };

    const submitForm = async () => {
      try {
        if (isEditing.value) {
          await axiosInstance.put(`/settings/${form.value.key}`, form.value);
          showToast('Настройка обновлена');
        } else {
          await axiosInstance.post('/settings', form.value);
          showToast('Настройка добавлена');
        }
        fetchSettings();
        closeModal();
      } catch (error) {
        showToast('Ошибка при сохранении настройки');
      }
    };

    const confirmDelete = (key) => {
      deletingKey.value = key;
      const modal = new Modal(document.getElementById('deleteModal'));
      modal.show();
    };

    const deleteSetting = async () => {
      try {
        await axiosInstance.delete(`/settings/${deletingKey.value}`);
        showToast('Настройка удалена');
        fetchSettings();
        const modal = Modal.getInstance(document.getElementById('deleteModal'));
        modal.hide();
      } catch (error) {
        showToast('Ошибка при удалении настройки');
      }
    };

    const showToast = (message) => {
      toastMessage.value = message;
      setTimeout(() => {
        toastMessage.value = '';
      }, 3000);
    };

    onMounted(fetchSettings);

    return {
      settings,
      isEditing,
      form,
      openModal,
      closeModal,
      editSetting,
      submitForm,
      confirmDelete,
      deleteSetting,
      toastMessage,
    };
  },
};
</script>

<style scoped>
.toast {
  opacity: 1 !important;
  transition: opacity 0.5s ease-in-out;
}
</style>
