<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>Список кейсов</h2>
        <button @click="openCreateModal" class="btn btn-primary">Добавить кейс</button>
    </div>

    <div v-if="cases.length > 0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th>Описание</th>
            <th width="300px" class="text-end">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="caseItem in cases" :key="caseItem.id">
            <td>{{ caseItem.id }}</td>
            <td>{{ caseItem.title }}</td>
            <td class="text-truncate" style="max-width: 300px;" :title="caseItem.description">
              {{ truncateText(caseItem.description, 50) }}
            </td>
            <td>
              <router-link :to="{ name: 'CaseDetails', params: { id: caseItem.id } }" class="btn btn-info btn-sm me-2">
                Подробнее
              </router-link>
              <button class="btn btn-warning btn-sm me-2" @click="editCase(caseItem)">Редактировать</button>
              <button class="btn btn-danger btn-sm" @click="confirmDelete(caseItem)">Удалить</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Модалка для создания и редактирования кейса -->
    <div class="modal fade" id="caseModal" tabindex="-1" aria-labelledby="caseModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ editingCase ? 'Редактировать кейс' : 'Создать кейс' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitCase">
              <div class="mb-3">
                <label for="title" class="form-label">Название</label>
                <input v-model="caseData.title" type="text" class="form-control" id="title" required />
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Описание</label>
                <textarea v-model="caseData.description" class="form-control" id="description" required></textarea>
              </div>
              <div class="d-flex">
                <button type="submit" class="btn btn-primary ms-auto">{{ editingCase ? 'Сохранить' : 'Создать' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Уведомления об ошибках или успешных действиях -->
    <div v-if="toastMessage" class="alert alert-info position-fixed bottom-0 end-0 p-3">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import axiosInstance from '../services/axiosInstance';

export default {
  data() {
    return {
      cases: [],
      caseData: {
        title: '',
        description: '',
      },
      editingCase: null,
      toastMessage: '',
    };
  },
  methods: {
    async fetchCases() {
      try {
        const response = await axiosInstance.get('/cases');
        this.cases = response.data;
      } catch (error) {
        this.showToast('Ошибка при загрузке кейсов');
      }
    },
    openCreateModal() {
      this.caseData = { title: '', description: '' };
      this.editingCase = null;
      const modal = new Modal(document.getElementById('caseModal'));
      modal.show();
    },
    editCase(caseItem) {
      this.caseData = { ...caseItem };
      this.editingCase = caseItem.id;
      const modal = new Modal(document.getElementById('caseModal'));
      modal.show();
    },
    async submitCase() {
      try {
        if (this.editingCase) {
          await axiosInstance.put(`/cases/${this.editingCase}`, this.caseData);
          this.showToast('Кейс обновлен');
        } else {
          await axiosInstance.post('/cases', this.caseData);
          this.showToast('Кейс создан');
        }
        this.fetchCases();
        const modal = Modal.getInstance(document.getElementById('caseModal'));
        modal.hide();
      } catch (error) {
        this.showToast('Ошибка при сохранении кейса');
      }
    },
    confirmDelete(caseItem) {
      if (confirm(`Вы уверены, что хотите удалить кейс "${caseItem.title}"?`)) {
        this.deleteCase(caseItem.id);
      }
    },
    async deleteCase(caseId) {
      try {
        await axiosInstance.delete(`/cases/${caseId}`);
        this.showToast('Кейс удален');
        this.fetchCases();
      } catch (error) {
        this.showToast('Ошибка при удалении кейса');
      }
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = '';
      }, 3000);
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
  },
  mounted() {
    this.fetchCases();
  },
};
</script>

<style>
.table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alert {
  z-index: 1050;
}
</style>
