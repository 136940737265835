import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import CaseListView from '../views/CaseListView.vue';
import CaseDetailsView from '../views/CaseDetailsView.vue';
import SettingsManager from '../views/SettingsManager.vue';


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/cases',
    name: 'CaseList',
    component: CaseListView,
  },
  {
    path: '/cases/:id',
    name: 'CaseDetails',
    component: CaseDetailsView,
    props: true,
  },
  {
    path: '/settings',
    name: 'SettingsManager',
    component: SettingsManager,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
